<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px" @submit.native.prevent>
      <el-form-item label="名称" prop="name">
        <el-input
            v-model="queryParams.name"
            placeholder="请输入名称"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
            @submit.native.prevent="false"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            v-permission="'crm:tagsScript:add'"
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
        >新增
        </el-button>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="rinseList">
      <el-table-column label="主键" align="center" prop="id"/>
      <el-table-column label="行为名称" align="center" prop="name"/>
      <el-table-column label="行为描述" align="center" prop="remarks"/>
      <el-table-column label="是否生效" align="center" prop="status">
        <template v-slot="scope">
          <span v-if="scope.row.status === 0">生效</span>
          <span v-else>停用</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              v-permission="'crm:tagsScript:update'"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
          >修改
          </el-button>
          <el-button
              v-permission="'crm:tagsScript:delete'"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleDelete(scope.row)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.limit"
        @pagination="getList"
    />


    <el-dialog title="编辑行为" top="60px" :visible.sync="open" append-to-body :close-on-click-modal="true">
      <el-form :model="EditForm" :rules="rules" size="small" ref="ruleForm" label-width="120px" label-position="right">
        <el-form-item label="行为名称" prop="name">
          <template #label>
            <el-tooltip class="item" effect="dark" content="言简意赅写明该行为功能" placement="top-start">
              <span><i class="el-icon-question"></i>行为名称</span>
            </el-tooltip>
          </template>
          <el-input v-model="EditForm.name"></el-input>
        </el-form-item>

        <el-form-item label="行为逻辑描述" prop="remarks">
          <template #label>
            <el-tooltip class="item" effect="dark" content="描述该行为作用和条件的备注,最好详细一些,以方便后续维护"
                        placement="top-start">
              <span><i class="el-icon-question"></i>行为逻辑描述</span>
            </el-tooltip>
          </template>
          <el-input v-model="EditForm.remarks" type="textarea"></el-input>
        </el-form-item>

        <el-form-item label="机会发生操作" prop="operate">
          <template #label>
            <el-tooltip class="item" effect="dark" content="在操作发生以后会触发条件判断" placement="top-start">
              <span><i class="el-icon-question"></i>机会发生操作</span>
            </el-tooltip>
          </template>
          <el-select v-model="tempoperate" multiple @change="handBefore">
            <el-option :value="item.name" :label="item.label" v-for="item in operates"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="content">
          <template #label>
            <el-tooltip class="item" effect="dark" content="符合条件的才会打上标签" placement="top-start">
              <span><i class="el-icon-question"></i>条件</span>
            </el-tooltip>
          </template>
          <el-card shadow="never">
            <el-form-item v-for="(item,index) in tempcontent" :label="item.label">
              <el-row>
                <template v-if="item.type === 'MONEY'">
                  <el-col :span="8">
                    <el-select v-model="item.compare">
                      <el-option value="LT" label="小于"></el-option>
                      <el-option value="LE" label="小于等于"></el-option>
                      <el-option value="EQ" label="等于"></el-option>
                      <el-option value="GE" label="大于等于"></el-option>
                      <el-option value="GT" label="大于"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="10">
                    <el-input v-model="item.value"></el-input>
                  </el-col>
                </template>

                <template v-if="item.type === 'DATE'">
                  <el-col :span="8">
                    <el-select v-model="item.compare">
                      <el-option value="LT" label="小于"></el-option>
                      <el-option value="LE" label="小于等于"></el-option>
                      <el-option value="EQ" label="等于"></el-option>
                      <el-option value="GE" label="大于等于"></el-option>
                      <el-option value="GT" label="大于"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="10">
                    <el-input v-model="item.value">
                      <template slot="append">天</template>
                    </el-input>
                  </el-col>
                </template>
                <el-button size="mini" @click="removeConditions(index)">删除</el-button>
              </el-row>
            </el-form-item>
            <el-dropdown @command="handleCommand" style="float: right;">
              <el-button type="primary" size="mini">
                添加条件<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu #default>
                <el-dropdown-item v-for="item in conditionsParams" :command="item.param">{{
                    item.label
                  }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-card>
        </el-form-item>

        <el-form-item label="添加标签" prop="labels">
          <template #label>
            <el-tooltip class="item" effect="dark" content="当符合条件时,再废弃标签执行后,打上标签"
                        placement="top-start">
              <span><i class="el-icon-question"></i>添加标签</span>
            </el-tooltip>
          </template>
          <el-select multiple filterable v-model="templabels">
            <el-option :key="item.id" :value="item.id" :label="item.name"
                       :disabled="item.status === 1"
                       v-for="item in tagsOptions"></el-option>
          </el-select>
          <el-button style="float: right" type="success" @click="openTagsDialog">标签管理</el-button>
        </el-form-item>


        <el-form-item label="废弃标签" prop="abandon">
          <template #label>
            <el-tooltip class="item" effect="dark" content="当符合条件打上标签时,会先去除废弃标签,以便维护标签群体"
                        placement="top-start">
              <span><i class="el-icon-question"></i>废弃标签</span>
            </el-tooltip>
          </template>
          <el-select multiple filterable v-model="tempabandon">
            <el-option :value="item.id" :label="item.name" :key="item.id"
                       v-for="item in tagsOptions"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-select v-model="EditForm.status">
            <el-option v-for="item in statusList" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm">立即创建</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="tagsDialog" title="标签管理" width="100%" top="50px" @close="closeTagsDialog">
      <Tags></Tags>
    </el-dialog>
  </div>
</template>

<script>
import * as tagsScriptApi from "@/api/crm/TagsScript";
import * as tagsApi from "@/api/crm/tags";
import Tags from '@/views/crm/Tags.vue'

export default {
  name: "TagsScript",
  components: {
    Tags
  },
  data() {
    return {
      statusList: [
        {label: "生效", value: 0},
        {label: "停用", value: 1},
        // {label: "删除", value: 9}
      ],
      tagsDialog: false,
      tagsOptions: [],
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // 参数配置表格数据
      rinseList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 10
      },
      // 表单参数
      EditForm: {
        history: 0,
        status: 0,
      },
      rules: {},
      conditions: [],
      conditionsParams: [
        {label: "消费金额", param: "dealAmount", type: "MONEY"},
        {label: "学习间隔", param: "learnDate", type: "DATE"},
      ],
      operates: [],
      tempcontent: [],
      templabels: [],
      tempabandon: [],
      tempoperate: [],
    }
  },
  created() {
    this.getList();
    this.findActions()
    this.findTagsOptions()
  },
  computed: {},
  methods: {
    openTagsDialog() {
      this.tagsDialog = true
    },
    closeTagsDialog() {
      this.findTagsOptions()
    },
    findTagsOptions() {
      tagsApi.options().then(res => {
        this.tagsOptions = res.data
      })
    },
    findActions() {
      tagsScriptApi.actions().then(res => {
        this.operates = res
      })
    },
    removeConditions(index) {
      this.tempcontent.splice(index, 1)
    },
    handleCommand(command) {
      for (let item of this.conditionsParams) {
        if (command === item.param) {
          this.tempcontent.push(item)
        }
      }
    },
    handBefore() {
      console.log("条件改变")
    },
    /** 查询参数配置列表 */
    getList() {
      this.loading = true;
      tagsScriptApi.listPage(this.queryParams).then(response => {
        this.rinseList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });

    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.receiveSync = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.EditForm = {
        history: 0,
        status: 0,
        content:[],
      };
      this.resetForm("ruleForm");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加配置";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.open = true

      let content = JSON.parse(row.content)
      let labels = row.labels?.split(",").map(Number) || []
      let abandon = row.abandon?.split(",").map(Number) || []
      let operate = row.operate?.split(",") || []

      this.EditForm = row
      this.tempcontent = content
      this.templabels = labels
      this.tempabandon = abandon
      this.tempoperate = operate

      console.log(this.EditForm)
    },
    handleDelete(row) {
      this.$confirm('是否确定删除《' + row.name + '》', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        tagsScriptApi.deleteRinse(row.id).then(res => {
          if (res.success) {
            this.getList()
            this.$message.success("删除成功")
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          let content = JSON.stringify(this.tempcontent)
          let operate = this.tempoperate?.join(",") || null
          let labels = this.templabels.join(",") || null
          let abandon = this.tempabandon.join(",") || null

          this.EditForm['content'] = content
          this.EditForm['operate'] = operate
          this.EditForm['labels'] = labels
          this.EditForm['abandon'] = abandon

          tagsScriptApi.save(this.EditForm).then(res => {
            if (res.success) {
              this.$message.success(res.msg)
              this.open = false
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          })

        }
      });
    },
  }
}
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
